import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    // 招生就业
    path: '/enroll',
    name: 'enroll',
    component: () => import('../views/enroll.vue')
  },
  {
    // 学校寄语
    path: '/wishes',
    name: 'wishes',
    component: () => import('../views/wishes.vue')
  }, {
    // 搜索
    path: '/search',
    name: 'search',
    component: () => import('../views/search.vue')
  }, {
    // 祝福留言
    path: '/student',
    name: 'student',
    component: () => import('../views/student.vue')
  },
  {
    // 
    path: '/partyBuilding',
    name: 'partyBuilding',
    component: () => import('../views/partyBuilding.vue')
  },
  {
    // 学校概括
    path: '/generalize',
    name: 'generalize',
    component: () => import('../views/generalize.vue')
  },
  {
    // 合作交流
    path: '/coagent',
    name: 'coagent',
    component: () => import('../views/coagent.vue')
  }, {
    // 新闻列表
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue')
  },
  {
    // 校园服务
    path: '/campus',
    name: 'campus',
    component: () => import('../views/campus.vue')
  },
  {
    // 马克思主义教学部
    path: '/lecturer',
    name: 'lecturer',
    component: () => import('../views/lecturer.vue')
  },{
    // 马克思主义教学部新闻列表
    path: '/redNews',
    name: 'redNews',
    component: () => import('../views/redNews.vue')
  },{
    // 文章页
    path: '/redTalents',
    name: 'redTalents',
    component: () => import('../views/redTalents.vue')
  },
  {
    // 文章页
    path: '/talents',
    name: 'talents',
    component: () => import('../views/talents.vue')
  },
  {
    // 文件下载
    path: '/fileDownload',
    name: 'fileDownload',
    component: () => import('../views/fileDownload.vue')
  }, {
    // 人才培养
    path: '/cultivate',
    name: 'cultivate',
    component: () => import('../views/cultivate.vue')
  }, {
    // 考试成绩查询
    path: '/result',
    name: 'result',
    component: () => import('../views/result.vue')
  }, {
    // 就业信息
    path: '/jobInfo',
    name: 'jobInfo',
    component: () => import('../views/jobInfo.vue')
  }, {
    // 机构子网站
    path: '/institution',
    name: 'institution',
    component: () => import('../views/institution.vue')
  }, {
    // 机构子网站
    path: '/institution1',
    name: 'institution1',
    component: () => import('../views/institution1.vue')
  }, {
    // 机构设置
    path: '/institutionSetting',
    name: 'institutionSetting',
    component: () => import('../views/institutionSetting.vue')
  },{
    // 文章页
    path: '/blueTalents',
    name: 'blueTalents',
    component: () => import('../views/blueTalents.vue')
  }






]

const router = new VueRouter({
  routes
})

export default router
