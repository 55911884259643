<template>
  <div>

<template v-if="titleShow">
  <div>
    <div :class=" bottomBg?'bottom2':'bottom'">
      <div class="box">
        <el-row class="content" v-if="typeShow">
          <el-col :span="8" class="content_left">
          
            <p>学校地址：{{ info.address }}</p>
            <p>招生电话：{{ info.phone}}</p>
            <p>
              学院办公室电话：{{ info.xphone}}
              <span style="margin-left:1.56vw;">传真号：{{ info.fix}}</span>
            </p>
            <p>
              电子邮箱：{{ info.email }}
              <span style="margin-left:2.6vw;">邮编：{{ info.code }}</span>
            </p>
          </el-col>
          <el-col :span="8">
            <div class="content_logo">
              <img src="../assets/logo-bottom.png" alt width="100%" />
            </div>
          </el-col>
          <el-col :span="8">
            <div class="content_right">
              <el-popover placement="top-start" width="170" trigger="hover">
                <div class="content_right_item" slot="reference">
                  <img src="../assets/media1.png"  alt width="100%" />
                </div>
                <div class="QRcode" slot>
                  <img :src="info.douyin" alt width="100%" />
                </div>
              </el-popover>
              <el-popover placement="top-start" width="170" trigger="hover">
                <div class="content_right_item" slot="reference">
                  <img src="../assets/media2.png" alt width="100%" />
                </div>
                <div class="QRcode" slot>
                  <img :src="info.weibo" alt width="100%" />
                </div>
              </el-popover>
              <el-popover placement="top-start" width="170" trigger="hover">
                <div class="content_right_item" slot="reference">
                  <img src="../assets/media3.png" alt width="100%" />
                </div>
                <div class="QRcode" slot>
          
                  <img :src="info.wechat" alt width="100%" />
                </div>
              </el-popover>
            </div>
          </el-col>
        </el-row>

        <!-- 类型2 -->
        <el-row class="another" type="flex" align="middle" v-else>
          <el-col :span="5">
            <img src="../assets/logo.png" alt width="100%" />
          </el-col>
          <el-col
            :span="19"
            style="display: flex;align-items: center;justify-content: space-between;"
          >
            <div class="another_pupil">
              <p v-if="textShow">{{ textName }}</p>
            </div>
            <div class="another_text">
              <p>学校地址：{{ info.address }}</p>
              <p>
                招生电话：{{ info.phone}}
                <span>办公室电话：{{ info.xphone}}</span>
                <span>电子邮箱：{{ info.email }}</span>
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div
      class="copyright"
      :class="bottomBg?'copyright2':''"
    >版权所有© {{ info.appname }} {{ info.icp }} {{ info.gongan }}</div>
  </div>
</template>
<template v-if="!titleShow">
  <div :class=" bottomBg?'bottom2':'bottom'">
    <div class="logo">
      <img src="../assets/logo-bottom.png" alt width="100%" />
    </div>
    <div class="texts">
      <p>学校地址：{{ info.address }}</p>
      <p>招生电话：{{ info.phone}}</p>
      <p> 学院办公室电话：{{ info.xphone}}</p> 
       <p style="margin-left:1.56vw;">传真号：{{ info.fix}}</p>
      <p>
        电子邮箱：{{ info.email }}
      
      </p>
    <p >邮编：{{ info.code }}</p>

    </div>
    

      <div class="content_right">
              <el-popover placement="top-start" width="170" trigger="hover">
                <div class="content_right_item" slot="reference">
                  <img src="../assets/media1.png" alt width="100%" />
                </div>
                <div class="QRcode" slot>
                  <img :src="info.douyin" alt width="100%" />
                </div>
              </el-popover>
              <el-popover placement="top-start" width="170" trigger="hover">
                <div class="content_right_item" slot="reference">
                  <img src="../assets/media2.png" alt width="100%" />
                </div>
                <div class="QRcode" slot>
                  <img :src="info.weibo" alt width="100%" />
                </div>
              </el-popover>
              <el-popover placement="top-start" width="170" trigger="hover">
                <div class="content_right_item" slot="reference">
                  <img src="../assets/media3.png" alt width="100%" />
                </div>
                <div class="QRcode" slot>
               
                  <img :src="info.wechat" alt width="100%" />
                </div>
              </el-popover>
            </div>
    <div
      class="copyright"
      :class="bottomBg?'copyright2':''"
    >
    
    <p>  版权所有© {{ info.appname }} </p>
    <p> {{ info.icp }} {{ info.gongan }}</p>
 
  </div>
  </div>
</template >
</div>
</template>

<script>
import { getConfig } from "@/utils/api";
export default {
  name: "XyBottom",
  props: {
    typeShow: {
      type: Boolean,
      default: true
    },
    textShow: {
      type: Boolean,
      default: true
    },
    textName: {
      type: String,
      default: "学生处"
    },
    bottomBg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      info: {},
      screenWidth:'',
      titleShow:true
    };
  },

  mounted() {
       //获取屏幕尺寸
       this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };

    if(config){
      this.info=config
    }else {
       this.init()
    }
  
  },

  watch: {
    screenWidth: function(n) {
      if(n<986){
        this.titleShow=false
      }else{
        this.titleShow=true
      }
      this.bannerH = (n * 967) / 1920;
      console.log(n);
    }
  },

  methods: {
    init(){
      getConfig({}, res => {
      if (res.code == 0) {
        res.data.douyin = JSON.parse(res.data.douyin)[0]?.url;
        res.data.weibo = JSON.parse(res.data.weibo)[0]?.url;
        res.data.wechat = JSON.parse(res.data.wechat)[0]?.url;
        config=res.data
        this.info = res.data;
        console.log('dayin',res.data);
      }
    });
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 968px){

.bottom {
  width: 100%;
  min-width: 1024px;
  background: url(../assets/bottom-bg.png) no-repeat;
  background-size: cover;
  overflow: hidden;
}
.bottom2 {
  width: 100%;
  min-width: 1024px;
  background: url(../assets/bottom-bg2.png) no-repeat;
  background-size: cover;
  overflow: hidden;
}

.content {
  padding: 1.66vw 0;
  &_left {
    font-size:max(12px,calc(100vw * 16px / 1920px));
    font-weight: 400;
    color: #ffffff;
    line-height: 1.87vw;
  }
  &_logo {
    width: 12.96vw;
    height: 8.23vw;
    margin: auto;
  }
  &_right {
    margin-top: 2.6vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &_item {
      width: 2.76vw;
      height: 2.76vw;
      overflow: hidden;
      margin-left: 1.04vw;
    
    }
  }
}
.copyright {
  width: 100%;
  background-color: #2e112d;
  padding: 1.04vw 0;
  text-align: center;
  font-size:max(12px,calc(100vw * 15px / 1920px));
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}
.copyright2 {
  background-color: #911f14;
}
.another {
  flex: 1;
  padding: 3.07vw 0px;
  &_pupil {
    font-weight: bold;
    font-size:max(12px,calc(100vw * 36px / 1920px));
    color: #ffffff;
    padding-left: 1.61vw;
    margin-left: 1.87vw;
    border-left: 1px solid #cbafd2;
  }
  &_text {
    font-weight: 400;
    font-size:max(12px,calc(100vw * 16px / 1920px));
    color: #ffffff;
    line-height: 1.87vw;
    > p > span {
      margin-left: 1.56vw;
    }
  }
}
.QRcode {
  width: 150px;
  margin-left:-3px;
  overflow: hidden;
}
}
@media screen and (max-width: 968px){
.bottom {
  width: 100%;
  background: url(../assets/bottom-bg.png) no-repeat;
  background-size: cover;
  overflow: hidden;
}
.bottom2 {
  width: 100%;
  background: url(../assets/bottom-bg2.png) no-repeat;
  background-size: cover;
  overflow: hidden;
}
.logo{
  width: 133px;
  height: 83px;
  margin:20px auto 16px;

}
.texts{
  >p{
    font-size: 11px;
    color: #ffffff;
    margin-bottom: 10px;
    text-align: center;
  }
}
.content {
  padding: 1.66vw 0;
  &_left {
    font-size: calc(100vw * 32px / 1920px);
    font-weight: 400;
    color: #ffffff;
    line-height: 2.87vw;
  }
  &_logo {
    width: 20.96vw;
    height: 14.23vw;
    margin: auto;
  }
  &_right {
    margin-top: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    &_item {
      width: 35px;
      height:35px;
      overflow: hidden;
      margin-right: 16px;
    }
    &_item:nth-of-type(3){
      margin-right: 0px;
    }
  }
}
.copyright {
  width: 100%;
  background-color: #2e112d;
  padding: 1.04vw 0;
  text-align: center;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  >p{
    text-align: center;
  }
}
.copyright2 {
  background-color: #911f14;
}
.another {
  flex: 1;
  padding: 3.07vw 0px;
  &_pupil {
    font-weight: bold;
    font-size: calc(100vw * 36px / 1920px);
    color: #ffffff;
    padding-left: 1.61vw;
    margin-left: 1.87vw;
    border-left: 1px solid #cbafd2;
  }
  &_text {
    font-weight: 400;
    font-size: calc(100vw * 16px / 1920px);
    color: #ffffff;
    line-height: 1.87vw;
    > p > span {
      margin-left: 1.56vw;
    }
  }
}
.QRcode {
  width: 150px;
  margin-left:-3px;
  overflow: hidden;
}
}

</style>