<template>
  <div
    class="pheader"
    :style="PBg==true? 'background-color: #B81A09;':'background-color: #5d216b;'"
  >
    <div class="pheader_logo" @click="homeChange">
      <img src="../assets/logo.png" alt width="100%" />
    </div>
    <div class="pheader_right">
      <img src="../assets/phone-icon.png" alt width="100%" @click="drawer = !drawer" />
    </div>

    <el-drawer
      size="100%"
      :modal="false"
      :modal-append-to-body="false"
      style="margin-top:70px;"
      direction="ttb"
      :visible.sync="drawer"
      :with-header="false"
    >
      <div class="pheader_right_text">
        <!-- <p v-for="(item,index) in info" :key="index" @click="router(item,index)">{{ item.title }}</p> -->

        <div class="fice">
          <div class="fice_text" v-if="!ficeShoaw" @click="sousuoChange">搜索</div>
          <div class="fice_input" v-if="ficeShoaw">
            <el-input ref="input" placeholder="请输入" v-model="input3">
              <i slot="suffix" class="el-input__icon el-icon-search" @click="inputChange" :size="38"></i>
            </el-input>
          </div>
        </div>

        <el-menu @open="handleOpen" @close="handleClose">
          <div v-for="(item,index) in info" :key="index">
            <el-submenu v-if="item.children.length>0" :index="index+1">
              <template slot="title">
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  v-for="(item2,index2) in item.children"
                  :key="index2"
                  :index="(index+1 )+'-'+(index2+1)"
                  @click="router(item2,index)"
                >{{ item2.title }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-menu-item
              v-if="item.children.length==0"
              :index="index+1"
              @click="router(item,index)"
            >
              <span slot="title">{{item.title}}</span>
            </el-menu-item>
          </div>
        </el-menu>

        <!-- <p @click="topChange('partyBuilding')">学校校友</p> -->
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getNav } from "@/utils/api";
export default {
  name: "XyPHeard",
  props: {
    PBg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drawer: false,
      ficeShoaw: false,
      input3:'',
      info: []
    };
  },

  mounted() {
    if (nav) {
      this.info = nav;
    } else {
      getNav({}, res => {
        if (res.code == 0) {
          localStorage.setItem("TBanner", res.data.list[0].banner);
          this.info = res.data.list;
          this.info.unshift({
            url: "",
            title: "OA系统",
            children: []
          });
          this.info.unshift({
            url: "partyBuilding",
            title: "学校校友",
            children: []
          });
          this.info.unshift({
            url: "",
            title: "人才招聘",
            children: []
          });
          this.info.unshift({
            url: "home",
            title: "首页",
            children: []
          });

          nav = this.info;
        }
      });
    }
  },

  methods: {
    router(item, index) {
      if (index > 3) {
        localStorage.setItem("TBanner", this.info[index].banner);
      }
      this.ficeShoaw=false,
      this.$router.push({ name: item.url });
    },
    homeChange() {
      this.$router.push({ name: "home" });
    },
    // 人才、校友、OA系统
    topChange(e) {
      this.$router.push({ name: e });
      console.log(e);
    },
    sousuoChange(){
      this.ficeShoaw=true
      setTimeout(() => {
        this.$refs.input.focus();
      }, 300);
    },
    inputChange(){
      this.$router.push(`/search?keyword=${this.input3}`);
    }
  }
};
</script>
<style lang="scss" scoped>
.pheader {
  width: 100%;
  height: 70px;
  background-color: #5d216b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
  position: fixed;
top: 0;
z-index: 99;

  &_logo {
    width: 41.06vw;
    height: 7.2vw;
  }
  &_right {
    width: 6.13vw;
    height: 4vw;
    &_text {
      height: 100%;
      padding-top: 3vw;
      > p {
        padding: 1vw 3vw;
        cursor: pointer;
      }
    }
  }
}
.fice {
  margin: 20px 20px;

  &_text {
    color: #303133;
    background-color: #88888838;
    text-align: center;
    line-height: 30px;
    border-radius: 20px;
    color: #888888;
  }
  
}
:deep .el-input__inner{
  font-size: 16px;
  font-size: 500;
}
:deep .el-input{
  font-size: 20px;
}
</style>