<template>
  <div>

    <template v-if="titleShow">
      
  

    <div class="header">
      <div
        class="header_top"
        :class="bgShow? 'bg':''"
        style=" border-bottom: 1px solid #ffffff;border-color: rgba(255, 255, 255, 0.3);"
      >
        <div class="home_title_box">
          <div class="home_title_box_hint">
            <!-- logo -->
            <div class="home_title_box_left">
              <img @click="homeChange" style="cursor: pointer;" src="../assets/logo.png" alt width="100%" />
            </div>
            <div class="home_title_box_right" style="position:relative ;">
              <div class="home_title_box_right_top" style="position: absolute;top:0;right: 0;">
                <div class="rapid">
                  <div class="rapid_item">人才招聘</div>
                  <div class="rapid_wier"></div>
                  <div
                    class="rapid_item"
                    @click="topChange('partyBuilding')"
                    style="cursor: pointer;"
                  >学校校友</div>
                  <div class="rapid_wier"></div>
                  <div class="rapid_item">OA系统</div>
                  <div class="rapid_wier"></div>
                  <div class="rapid_img" style="position: relative;">
                    <img src="../assets/seach.png" alt width="100%" @click="seachShowChange" />
                    <el-input
                      v-if="seachShow"
                      placeholder="请输入内容"
                      v-model="keyword"
                      @blur="blurChange"
                      @keyup.enter.native="keywordChange"
                      ref="input"
                      autofocus
                      style="background-color: #ffffff;position: absolute; top: -0.5vw;right: 0;width: 12.23vw;height:1.5vw;border-radius: 1.04vw;display: flex;align-items: center;"
                    >
                      <div slot="suffix" style="width:1.09vw;margin-left: 0.6vw;margin-top: 0.1vw;display: flex;align-items: center;">
                        <img
                          src="../assets/seach2.png"
                          @click="keywordChange"
                          style="width:100%;"
                          width="100%"
                          alt
                        />
                      </div>
                    </el-input>
                  </div>
                </div>
              </div>
              <div
                class="home_title_box_right_bottom"
                style="margin-left: 9.9%;width: 90.1%;margin-top: 2vw;"
              >
                <div class="rapid" style="justify-content: space-between;">
                  <div
                    class="rapid_text"
                    v-for="(item,index) in  info"
                    :key="index"
                    :class="headWier==index? 'action':''"
                  >
                    <p @click="headerChange(item.url,index)">{{ item.title }}</p>
                    <!-- 子标题 -->
                    <div class="rapid_text_box" v-if="item.children.length>0">
                      <p
                        @click="headerChange(item2.url,4)"
                        v-for="(item2,index2) in item.children"
                        :key="index2"
                      >{{ item2.title }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header_img" v-if="headerImg">
        <!-- <div
          v-if="type=='introduce' || headerNum=='partyBuilding'||headerNum=='search'||type=='search'"
        >
          <img :src="headerImg" alt width="100%" />
        </div>
        <div v-else>
          <img
            v-for="(item,index) in info"
            :key="index"
            v-show="headerNum==item.url||type==item.url"
            :src="item.banner"
            alt
            width="100%"
          />
        </div> -->
        <img :src="TBanner" alt="" width="100%">
        <div class="header_img_text" v-if="headerText" >
          <img src="../assets/student.png" alt style="weight:0.83vw;height:0.78vw;cursor: pointer;"  @click="homeChange"/>
          <p style="cursor: pointer;" @click="homeChange">首页</p>
          <p>/</p>
          <p>{{headerText}}</p>
          <p v-if="headerText1">/</p>
          <p v-if="headerText1">{{headerText1}}</p>
        </div>
      </div>
    </div>
  </template>

<template v-if="!titleShow">
  <PHeard/>
  <div class="header_img" v-if="headerImg">
        <!-- <div
          v-if="type=='introduce' || headerNum=='partyBuilding'||headerNum=='search'||type=='search'"
        >
          <img :src="headerImg" alt width="100%" />
        </div>
        <div v-else>
          <img
            v-for="(item,index) in info"
            :key="index"
            v-show="headerNum==item.url||type==item.url"
            :src="item.banner"
            alt
            width="100%"
          /> 
          
         </div> -->
        <img :src="TBanner" alt="" width="100%">
        <div class="header_img_text" v-if="headerText">
          <img @click="homeChange" src="../assets/student.png" alt style="weight:2vw;height:2vw;cursor: pointer;" />
          <p style="cursor: pointer;" @click="homeChange">首页</p>
          <p >/</p>
          <p >{{headerText}}</p>
          <p v-if="headerText1">/</p>
          <p v-if="headerText1">{{headerText1}}</p>
        </div>
      </div>
</template>


  </div>
</template>

<script>
import { getNav } from "@/utils/api";
import PHeard from "@/components/PHeard";
export default {
  name: "XyHeader",
  components:{
    PHeard
  },
  props: {
    bgShow: {
      type: Boolean,
      default: false
    },
    headerNum: {
      type: String,
      default: ""
    },
    headerImg: {
      type: String,
      default: ""
    },
    headerText: {
      type: String,
      default: ""
    },
    headerText1: {
      type: String,
      default: ""
    },
    keywordShow: {
      type: Boolean,
      default: false
    },
    keywordVal: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    headWier: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      screenWidth: "",
      bannerH: "",
      info: [],
      seachShow: this.keywordShow,
      keyword: this.keywordVal, //搜索值
      titleShow:true,
      TBanner:''
    };
  },
  created() {
    if(nav){
      this.info =nav;
       
    }else{
      getNav({}, res => {
      if (res.code == 0) {
        localStorage.setItem("TBanner",res.data.list[0].banner );
        this.info = res.data.list;
        this.info.unshift({
          url: "home",
          title: "首页",
          children: []
        });
      
        nav= this.info
      }
    });
    }
    
    this.TBanner=localStorage.getItem('TBanner')
  },
  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: function(n) {
      if(n<986){
        this.titleShow=false
      }else{
        this.titleShow=true
      }
      this.bannerH = (n * 967) / 1920;
      console.log(n);
    }
  },
  methods: {
    // 头部点击
    headerChange(e, index) {
      if(index!=0){
        localStorage.setItem("TBanner",this.info[index].banner );
      }

      if (index == 4) {
        localStorage.setItem("head", "B");
        localStorage.setItem("headWier", index);

      } else {
        localStorage.setItem("head", "A");
        localStorage.setItem("headWier", index);
      }

      if (e == this.headerNum) {
        return false;
      } else {
        this.$router.push({ name: e });
      }
    },
    // 人才、校友、OA系统
    topChange(e) {
      localStorage.setItem("head", "A");
      localStorage.setItem("headWier", -1);
      this.$router.push({ name: e });
      console.log(e);
    },
    keywordChange() {
      localStorage.setItem("head", "A");
      localStorage.setItem("headWier", -1);
      if (this.$route.name != "search") {
        if (this.keyword) {
          this.$router.push(`/search?keyword=${this.keyword}`);
        }
      } else {
        this.$emit("searchChage", this.keyword);
      }
    },

    seachShowChange() {
      this.seachShow = true;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 300);
    },
    blurChange() {
      if (!this.keyword) {
        this.seachShow = false;
      }
    },
    homeChange(){
      this.$router.push({ name: 'home' });
    },
    
  }
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 968px){
.header {
  width: 100%;
  min-width: 1024px;
  margin: auto;
  position: relative;

  &_img {
    width: 100%;
    position: relative;
    &_text {
      width: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      bottom:6px;
      // bottom: 0.3vw;
      // left: 8%;
      padding-left: 8%;
      padding-bottom: 1.25vw;
      padding-top: 3vw;
      color: #ffffff;
      background-image: url('/src/assets/banner-text-bg.png');
      background-size: 100% 100%;
      > p {
        margin-left: 0.67vw;
      }
    }
  }
  &_top {
    width: 100%;
    min-width: 1024px;
    background: url(http://handan-oss.oss-cn-beijing.aliyuncs.com/20240409/797420c78be581da8d5a8d6ccc0e9b27.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    // left: 0;
    z-index: 9;
  }
}



.home_title_box {
  width: 100%;
  min-width: 1024px;
  max-width: 1920px;
  margin: auto;
  padding: 0 4%;

  &_hint {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 1.4vw;
  }
  &_left {
    width: 24.8%;
  }
  &_right {
    flex: 1;
    &_bottom {
      flex: 1;
    }
  }

  :deep .el-carousel__indicator--horizontal .el-carousel__button {
    width: 0.625rem;
    height: 0.625rem;
    background-color: #ffffff;
    border-radius: 50%;
    opacity: 0.5;
    margin-bottom: 0.9375rem;
  }
  :deep .el-carousel__indicator--horizontal.is-active .el-carousel__button {
    width: 0.625rem;
    height: 0.625rem;
    background: #ffffff;
    border-radius: 50%;
    opacity: 1;
    margin-bottom: 0.9375rem;
  }
}
.bg {
  background: url(../assets/header-bg.png) no-repeat;
}
.rapid {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;

  &_item {

    font-size:max(12px,calc(100vw * 14px / 1920px));
    font-weight: 400;
    color: #ffffff;
    opacity: 0.8;
    cursor: pointer;
  }
  &_wier {
    width: 0.0625rem;
    height: 0.875rem;
    background: #ffffff;
    opacity: 0.3;
    margin: 0 0.9375rem;
  }
  &_img {
    width: 1.08vw;
    height: 1.04vw;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &_text {
    font-size:max(12px,calc(100vw * 22px / 1920px));
    font-weight: 400;
    color: #ffffff;
    padding-bottom: 1.8vw;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    position: relative;
  
    &_box {
      position: absolute;
      bottom: -5.46vw;
      left: -0.78vw;
      display: none;
      width: 6.25vw;
      font-weight: bold;
      font-size:max(12px,calc(100vw * 19px / 1920px));
      color: #ffffff;
      line-height: 2.65vw;
      background: #833a94c0;
      text-align: center;
      
    }
  }
  &_text:hover{
    border-bottom: 2px solid #ffffff;
  }
}
.rapid_text:hover .rapid_text_box {
  display: block;
}
.action {
  border-bottom: 2px solid #ffffff;
}

}

@media screen and (max-width: 968px){
.header {
  width: 100%;
  margin: auto;
  position: relative;

  &_img {
    width: 100%;
    position: relative;
    margin-top: 70px;
    &_text {
      width: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 4px;
      // left: 8%;
      padding-left: 4%;
      padding-bottom: 10px;
      padding-top:10px;
      color: #ffffff;
      background-image: url('/src/assets/banner-text-bg.png');
      background-size: 100% 100%;
      > p {
        margin-left: 0.67vw;
        font-size:9px
      }
    }
  }
  &_top {
    width: 100%;
    background: url(http://handan-oss.oss-cn-beijing.aliyuncs.com/20240409/797420c78be581da8d5a8d6ccc0e9b27.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    // left: 0;
    z-index: 9;
  }
}


.home_title_box {
  width: 100%;
  margin: auto;
  padding: 0 6%;

  &_hint {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 1.4vw;
  }
  &_left {
    width: 24.8%;
  }
  &_right {
    flex: 1;
    &_bottom {
      flex: 1;
    }
  }

  :deep .el-carousel__indicator--horizontal .el-carousel__button {
    width: 0.625rem;
    height: 0.625rem;
    background-color: #ffffff;
    border-radius: 50%;
    opacity: 0.5;
    margin-bottom: 0.9375rem;
  }
  :deep .el-carousel__indicator--horizontal.is-active .el-carousel__button {
    width: 0.625rem;
    height: 0.625rem;
    background: #ffffff;
    border-radius: 50%;
    opacity: 1;
    margin-bottom: 0.9375rem;
  }
}
.bg {
  background: url(../assets/header-bg.png) no-repeat;
}
.rapid {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;

  &_item {
    font-size: calc(100vw * 14px / 1920px);
    font-weight: 400;
    color: #ffffff;
    opacity: 0.8;
    cursor: pointer;
  }
  &_wier {
    width: 0.0625rem;
    height: 0.875rem;
    background: #ffffff;
    opacity: 0.3;
    margin: 0 0.9375rem;
  }
  &_img {
    width: 1.3125rem;
    height: 1.25rem;
    cursor: pointer;
  }
  &_text {
    font-size: calc(100vw * 22px / 1920px);
    font-weight: 400;
    color: #ffffff;
    padding-bottom: 1.8vw;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    position: relative;
    &_box {
      position: absolute;
      bottom: -5.46vw;
      left: -0.78vw;
      display: none;
      width: 6.25vw;
      font-weight: bold;
      font-size: calc(100vw * 19px / 1920px);
      color: #ffffff;
      line-height: 2.65vw;
      background: #833a94;
      text-align: center;
    }
  }
}
.rapid_text:hover .rapid_text_box {
  display: block;
}
.action {
  border-bottom: 2px solid #ffffff;
}

}

</style>