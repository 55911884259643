import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import BaiduMap from 'vue-baidu-map';
// 百度地图

Vue.use(BaiduMap, {
  // ak: 'a1FxSgX6EAnLxjxGGVRFfokfWujvRvtx'
})

Vue.prototype.$video = videojs;
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
