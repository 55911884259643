import http from './request'

export const getConfig = (params, callback) => { http.post('getConfig', params).then(res => { callback(res) }) }//公共底部
export const getIndex = (params, callback) => { http.post('getIndex', params).then(res => { callback(res) }) }//首页
export const getBanner = (params, callback) => { http.post('getBanner', params).then(res => { callback(res) }) }//轮播图
export const getOnePage = (params, callback) => { http.post('getOnePage', params).then(res => { callback(res) }) }//学校概括、地图
export const getNewsCate = (params, callback) => { http.post('getNewsCate', params).then(res => { callback(res) }) }//新闻分类
export const newsList = (params, callback) => { http.post('newsList', params).then(res => { callback(res) }) }//新闻列表
export const newsInfo = (params, callback) => { http.post('newsInfo', params).then(res => { callback(res) }) }//新闻详情
export const getJiGou = (params, callback) => { http.post('getJiGou', params).then(res => { callback(res) }) }//机构设置
export const zhaoList = (params, callback) => { http.post('zhaoList', params).then(res => { callback(res) }) }//招生就业
export const zhaoInfo = (params, callback) => { http.post('zhaoInfo', params).then(res => { callback(res) }) }//招生详情
export const styleList = (params, callback) => { http.post('styleList', params).then(res => { callback(res) }) }//校园风采列表
export const styleInfo = (params, callback) => { http.post('styleInfo', params).then(res => { callback(res) }) }//校园风采详情
export const getBumenList = (params, callback) => { http.post('getBumenList', params).then(res => { callback(res) }) }//获取部门信息
export const upMessage = (params, callback) => { http.post('upMessage', params).then(res => { callback(res) }) }//寄语提交
export const messageList = (params, callback) => { http.post('messageList', params).then(res => { callback(res) }) }//寄语列表
export const checkResult = (params, callback) => { http.post('checkResult', params).then(res => { callback(res) }) }//成绩查询
export const getBumencate = (params, callback) => { http.post('getBumencate', params).then(res => { callback(res) }) }//获得机构子网页导航与名称
export const getBumencateNewsList = (params, callback) => { http.post('getBumencateNewsList', params).then(res => { callback(res) }) }//获得机构子网页文章
export const getNav = (params, callback) => { http.post('getNav', params).then(res => { callback(res) }) }//获取网站栏目
export const getContact = (params, callback) => { http.post('getContact', params).then(res => { callback(res) }) }//公共底部





