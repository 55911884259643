import axios from 'axios'
import router from '../router/index'
import { Loading } from 'element-ui';
import { Message } from 'element-ui'
var loadingcontext;
var statesVal = ''

axios.defaults.baseURL = "//admin.hvcat.com/app/api/"

axios.defaults.timeout = 10000
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  config.headers.token = token
  return config
})
axios.interceptors.response.use(response => {
  if (loadingcontext) {
    loadingcontext.close()
  }

  // console.log(response);
  if (response.status === 200) {
    if (!statesVal) {
      if (response.data.code == 401) {
        // this.$message.error('');
        Message.error({
          message: '请登录'
        })
        localStorage.clear()
        router.replace({ name: "login" })
        // push();
      }
    } else {
      if (response.data.status > 0) {
        Message.error({
          message: response.data.message
        })

      }
    }
    return Promise.resolve(response.data)
  } else {
    return Promise.reject(response)
  }
}, error => {
  // console.log(error)
  return Promise.reject(error.response)
  // if (error.response.status) {
  //   console.log(error.response.status);
  // }
})
var login = function (url, params, showLoading = true) {
  if (showLoading) {
    loadingcontext = Loading.service({
      lock: false
    })
  }
  return new Promise((resolve, reject) => {
    // console.log(params);
    // multipart/form-data
    axios.post(url, params, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(res => {
        // console.log("请求成功",res);
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

var post = function (url, params, headers = 'application/json', showLoading = true) {
  var lock = true
  if (showLoading) {
    setTimeout(() => {
      if (lock) {
        loadingcontext = Loading.service({
          lock: false
        })
      }
    }, 1200)
  }

  return new Promise((resolve, reject) => {
    // console.log(params);
    // multipart/form-data
    axios.post(url, params, { headers: { 'Content-Type': headers } })
      .then(res => {
        lock = false
        // console.log("请求成功",res);
        // resolve(res)
        if (res.code == 0) {
          resolve(res)
        } else if (res.code == 10086) {
          // 登录过期
          Message({
            type: 'error',
            message: res.msg
          })
          setTimeout(() => {
            router.replace({ name: "login" })
         
          }, 1500);
        } else {
          Message({
            type: 'error',
            message: res.msg
          })
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

var get = function (url, params, showLoading = true) {
  var lock = true
  if (showLoading) {
    setTimeout(() => {
      if (lock) {
        loadingcontext = Loading.service({
          lock: false
        })
      }
    }, 1200)
  }
  // 多个参数搜索
  if (params) {
    var paramsn = []
    Object.entries(params).forEach(([key, value]) => {
      let param = key + '=' + value;
      paramsn.push(param);
    });
    url += '?' + paramsn.join('&');
  }
  return new Promise((resolve, reject) => {
    if (url.substring(0, 4) == 'http') {
      axios.defaults.baseURL = ""
      // axios.defaults.headers.token =''
      statesVal = 1
    } else {
      statesVal = ''
      axios.interceptors.request.use(config => {
        const token = localStorage.getItem('token')
        config.headers.token = token
        // console.log('config',config);
        return config
      })

      // axios.defaults.baseURL = url//测试接口
    }
    axios.get(url, params, { headers: { 'Content-Type': 'application/json' } })
      .then(res => {
        lock = false
        resolve(res)

      })
      .catch(err => {
        reject(err)
      })
  })
}
var put = function (url, params, headers = 'application/json', showLoading = true) {
  var lock = true
  if (showLoading) {
    setTimeout(() => {
      if (lock) {
        loadingcontext = Loading.service({
          lock: false
        })
      }
    }, 1200)
  }

  return new Promise((resolve, reject) => {
    // console.log(params);
    // multipart/form-data
    axios.put(url, params, { headers: { 'Content-Type': headers } })
      .then(res => {
        lock = false
        // console.log("请求成功",res);
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

var del = function (url, params, showLoading = true) {
  var lock = true
  if (showLoading) {
    setTimeout(() => {
      if (lock) {
        loadingcontext = Loading.service({
          lock: false
        })
      }
    }, 1200)
  }
  return new Promise((resolve, reject) => {
    // console.log(params);
    // multipart/form-data
    axios.delete(url, params, { headers: { 'Content-Type': 'application/json' } })
      .then(res => {
        lock = false
        // console.log("请求成功",res);
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}


// var put = function (url, params, headers = 'application/json', showLoading = true) {
//   var lock = true
//   if (showLoading) {
//     setTimeout(() => {
//       if (lock) {
//         loadingcontext = Loading.service({
//           lock: false
//         })
//       }
//     }, 1200)
//   }

//   return new Promise((resolve, reject) => {
//     // multipart/form-data
//     axios.put(url, params, { headers: { 'Content-Type': headers } })
//       .then(res => {
//         lock = false
//         // console.log("请求成功",res);
//         resolve(res)
//       })
//       .catch(err => {
//         reject(err)
//       })
//   })
// }

export default {
  post,
  get,
  login,
  del,
  put
}